<template>
    <v-data-table
        :items="items"
        :headers="headers"
        :loading="loading"
        :search="search"
        :lazy="true"
        :items-per-page="10"
    >
        <template v-if="filtro" v-slot:top>
            <v-text-field
            v-model="search"
            label="PESQUISAR"
            class="mx-4"
            ></v-text-field>
      </template>
    </v-data-table>
</template>
<script>
export default {
    name:"Table",
    props:{
        items:{
            type:Array
        },
        filtro:{
            type:Boolean,
            default:true,
        },
        loading:{
            type:Boolean
        }
    },
    data:()=>({
        search:'',
    }),
    computed:{
        headers(){
            let h =[]
            if(this.items.length>0){
                h = Object.keys(this.items[0])
            }
            let res = h.map(e=>{
                return {text:e,sortable:true,value:e}
            })
            return res
        }
    }
}
</script>